<template>
  <div class="header" :style="{ background: subjectColor }">
    <div class="logo">
      <img :src="logo" height="48" width="48" style="width:auto;" />
      <!-- <span>{{ title }}</span> -->
    </div>
    <!-- <div class="collapse-btn" @click="handleCollapseChange">
      <i v-if="!collapse" class="el-icon-s-fold" style="color:#C0CBCC"></i>
      <i v-else class="el-icon-s-unfold" style="color:#C0CBCC"></i>
    </div> -->
    <div class="header-right">
      <div class="header-user-con">
        <!-- <div class="btn-fullscreen" @click="handleFullScreen">
          <el-tooltip effect="dark" :content="fullScreen?`取消全屏`:`全屏`" placement="bottom">
            <i class="el-icon-rank cblue"></i>
          </el-tooltip>
        </div> -->

        <el-dropdown class="user-name" trigger="click" @command="handleCommand">
          <span class="el-dropdown-link" style="color: rgba(0, 0, 0, 0.65)">
            {{ userInfo.userName + "-" }}{{ userInfo.roleId ? "boss" : "成员" }}
            <i class="el-icon-arrow-down"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item divided command="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <div class="user-avator">
          <img :src="userInfo.avatarUrl" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bus from "../../service/bus";
import utils from "../../utils/index";
import { getCompany,getCompanyId } from "@/api/miniProgram.js";

import { queryOemDomain } from "@/api/oemConfig.js";

export default {
  name: "commonHeader",
  data() {
    return {
      collapse: false,
      fullScreen: false,
      userInfo: this.$store.state.loginRoot.userInfo,
      title: "CRM系统准备中",
      logo: "",
      icon: "",
    };
  },
  async created() {
    this.getCompanyId();
    // if(document.domain != "localhost" && document.domain != "crm.youdomain.com"){
    // const oemConfig = await queryOemDomain({oemDomain:'crm.jquen.com'});
    let company_info = await getCompany();
    if (company_info.data.companyId) {
      localStorage.setItem('companyId',company_info.data.companyId)
      const oemConfig = await queryOemDomain({ oemDomain: document.domain || 'crm.jquen.com', companyId: company_info.data.companyId });
      if (!oemConfig) {
        this.$message.error('oem配置错误,请联系售后');
        return;
      }
      this.setOemConfig(oemConfig);
    }


    // }else{
    //   this.setOemConfig();
    // }
    // const logoData = await getCompany();
    // if (logoData) {
    //   this.setOemConfig(logoData)
    // }
  },
  computed: {
    // 主题颜色
    subjectColor() {
      return this.$store.state.subjectColor;
    },
  },
  methods: {
    getCompanyId(){
      getCompanyId({isCompanyKey:1}).then(res=>{
        console.log(res,'8888****');
      })
    },
    setOemConfig(oemConfig) {
      //获取icon
      var link =
        document.querySelector("link[rel*='icon']") ||
        document.createElement("link");
      link.type = "image/x-icon";
      link.rel = "shortcut icon";
      let oem_info = JSON.parse(localStorage.getItem('oem_info'));
      if (oemConfig && oemConfig.data) {
        if (oemConfig.data.iconLogo) {
          link.href = oemConfig.data.iconLogo;
        }else{
          link.href = oem_info.iconLogo;
        }
        document.getElementsByTagName("head")[0].appendChild(link);
        //获取网页标题
        if(oemConfig.data.oemName&&oemConfig.data.oemName!==''){
          document.title = oemConfig.data.oemName;
        }else{
          document.title = oem_info.oemName || "CRM系统正在准备中";
        }
        this.title = oemConfig.data.oemName||'';
        if(oemConfig.data.oemLogo&&oemConfig.data.oemLogo!==''){
          this.logo = oemConfig.data.oemLogo;
        }else{
          this.logo = oem_info.oemLogo || '';
        }
      } else {
        document.getElementsByTagName("head")[0].appendChild(link);
        //获取网页标题
        document.title = oem_info.oemName || "CRM系统正在准备中";
        this.title = oem_info.oemName || "";;
        this.logo = oem_info.oemLogo || '';
      }
    },
    // 全屏
    handleFullScreen() {
      let element = document.documentElement;
      if (this.fullScreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          element.msRequestFullscreen();
        }
      }
      this.fullScreen = !this.fullScreen;
    },

    // 下拉菜单选择
    handleCommand(commond) {
      if (commond === "logout") {
        utils.clearLoginInfo();
      }
    },

    // 控制折叠面板
    handleCollapseChange() {
      this.collapse = !this.collapse;
      bus.$emit("collapse", this.collapse);
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 48px;
  font-size: 22px;
  background-color: #6190e8;
  color: #ffffff;
  box-shadow: 0px 2px 6px rgba(115, 127, 128, 0.12);
  z-index: 2;
  /* border-bottom: 1px solid #eee; */
}

.collapse-btn {
  float: left;
  padding: 0 21px;
  cursor: pointer;
  line-height: 48px;
  color: #ffffff;
  margin-left: 23px;
}

.header .logo {
  float: left;
  line-height: 48px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #ffffff;
  // margin-left: 20px;
}

.header .logo img {
  // vertical-align: middle;
  margin-right: 8px;
}

.header .logo span {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 900;
  color: $color-1;
}

.header-right {
  float: right;
  padding-right: 20px;
}

.header-user-con {
  display: flex;
  height: 48px;
  align-items: center;
}

.btn-fullscreen {
  transform: rotate(45deg);
  margin-right: 5px;
  font-size: 24px;
}

.btn-bell,
.btn-fullscreen {
  position: relative;
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 15px;
  cursor: pointer;
}

.btn-bell-badge {
  position: absolute;
  right: 0;
  top: -2px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: #f56c6c;
  color: #999;
}

.btn-bell .el-icon-bell {
  color: #999;
}

.user-name {
  margin: 0px 5px;
}

.user-avator {
  margin: 0px 5px;
}

.user-avator img {
  display: block;
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.el-dropdown-link {
  color: #ffffff;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.el-dropdown-menu__item {
  text-align: center;
}
</style>
